<script lang="ts" setup>
import Loading from '~/components/layout/Loading.vue';
import LayoutPageHeader from '~/components/layout/LayoutPageHeader.vue';
import LayoutHeader from '~/components/header/LayoutHeader.vue';
import Notifications from '~/components/notifications/Notifications.vue';

defineOptions({
  name: 'DefaultLayout',
  middleware: 'recent-pages',
});

useStatsStore();
const route = useRoute();
const key = computed(() => {
  // if (route.name === 'visits-id') return 'visits-id';
  return route.fullPath;
  /**
   * Кейсы для проверки:
   * 1. Создать sfa задачу (перебросит на новую страницу), снова создать, должна открыться новая задача
   * 2. При смене активных фоток в визите не должна происходить перезагрузка страницы
   */
});

const appStore = useAppStore();
const { fixedHeader, fullPage, defaultPadding, darkBackground } = storeToRefs(appStore);
const i18n = useI18n();
const title = computed(() => i18n.t(appStore.title));

useSeoMeta({
  title,
});
</script>

<template lang="pug">
.app-main
  Loading
  LayoutHeader
  Notifications
  UiModalConfirmModule
  .app-wrap(:class="{'_full-page': fullPage.desktop, '_full-page-mobile': fullPage.mobile, '_dark-background': darkBackground }")
    LayoutPageHeader(:class="{'position-sticky t-0 z-index-1': fixedHeader}")
    .page-container(
      :class="{ '_default-padding': defaultPadding }"
    )
      slot
</template>

<style lang="scss" scoped>
@import "@frontend/ui-kit/src/runtime/styles/colors";
@import "@frontend/ui-kit/src/runtime/styles/adaptive";

.app-main {
  height: var(--full-height);
  position: relative;
  padding-top: 48px;
}

.page-container {
  display: flex;
  width: 100%;

  &._default-padding {
    padding: 0 24px;
  }
}

.app-wrap {
  display: flex;
  flex-flow: column;
  min-height: 100%;
  height: 100%;
  overflow-y: auto;
  position: relative;
  scroll-padding-top: 60px;

  &._dark-background {
    background: $ui-color-bg-secondarylight;

    .page-container {
      padding: 20px 8px 22px;
    }
  }
}

@media (min-width: 1025px) {
  .app-wrap {
    &._full-page {
      overflow: hidden;

      .page-container {
        height: 100%;
        overflow: hidden;
        padding: 0;
      }
    }
  }
}

@include _1024 {
  .page-container {
    &._default-padding {
      padding: 0 16px;
    }
  }

  .app-wrap {
    &._full-page-mobile {
      overflow: unset;
      overflow-y: auto;

      .page-container {
        overflow: unset;
        padding: 0;
      }
    }
  }
}
</style>
