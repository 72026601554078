<script setup lang="ts">
import PageTitle from '~/components/layout/PageTitle.vue';
import { UiTeleportNames } from '~/components/UiTeleportNames';

const {
  backLink,
  breadcrumbs,
  titleShadow,
  headerTeleportId,
  title: titleLocale,
  mobileHeaderBorder
} = storeToRefs(useAppStore());

const teleportLeftName = computed(() => `${UiTeleportNames.Header.Left}-${headerTeleportId.value}`);
const teleportRightName = computed(() => `${UiTeleportNames.Header.Right}-${headerTeleportId.value}`);
const teleportBottomName = computed(() => `${UiTeleportNames.Header.Bottom}-${headerTeleportId.value}`);
</script>

<template lang="pug">
.page-header(:class="{'_shadow': titleShadow, '_mobile_border': mobileHeaderBorder}")
  .page-header-top
    .page-header-left
      UiBreadcrumbs.breadcrumbs(:breadcrumbs="breadcrumbs")
      .page-header-title
        UiIconButton.m-r-8(
          v-if="backLink"
          :size="24"
          :to="backLink"
          :href="backLink"
          :link="true"
          :padding="false"
          :name="UiIconName.Arrows_LeftArrow"
          :theme="UiStyles.UiTheme.Secondary"
        )
        UiTeleportTarget(:name="teleportLeftName")
          PageTitle(:title="titleLocale")
    .page-header-right
      UiTeleportTarget(:name="teleportRightName")
  UiTeleportTarget(:name="teleportBottomName")
</template>

<style lang="scss" scoped>
@import "@frontend/ui-kit/src/runtime/styles/colors";
@import "@frontend/ui-kit/src/runtime/styles/spacing";
@import "@frontend/ui-kit/src/runtime/styles/adaptive";

.page-header {
  @include column_with_space(12px);
  padding: 8px 24px 12px;
  background: $ui-color-bg-default;
  //overflow: hidden;
  flex-shrink: 0;

  &._shadow {
    padding-bottom: 16px;
    border-bottom: 2px solid rgba(225, 226, 230, 0.5);
  }

  @include _1024 {
    padding: 12px 16px;

    &._mobile_border {
      border-bottom: 1px solid $ui-color-border-default;
    }
  }
}

.page-header-top {
  @include elements_with_space(12px);
  flex-flow: wrap;
  justify-content: space-between;
  align-items: flex-end;
  overflow: hidden;
}

.page-header-left {
  overflow: hidden;
}

.page-header-title {
  display: flex;
  align-items: center;
  padding-top: 8px;

  @include _1024 {
    padding-top: 0;
  }
}

.breadcrumbs {
  margin-bottom: 2px;
}

.page-header-right {
  margin-left: auto;
}
</style>
