<script setup lang="ts">
import { storeToRefs } from 'pinia';

defineOptions({
  name: 'NotificationsToasts',
});

const notificationsStore = useNotificationsStore();

const { notifications } = storeToRefs(notificationsStore);

function closeNotification(id) {
  notificationsStore.deleteNotification(id);
}
</script>

<template lang="pug">
TransitionGroup.notifications-container(name="notification" tag="div" data-qa="notifications")
  .notification-item(
    v-for="item in notifications"
    :key="item.id"
    data-qa="notification-item"
    :class="[`_${item.type}`]"
  )
    .notification-message(data-qa="notification-message") {{ $t(...toArray(item.message)) }}
    UiIconButton.close-btn(
      :name="UiIconName.UserInterface_CloseThin"
      :size="16"
      @click="closeNotification(item.id)"
    )
</template>

<style lang="scss" scoped>
@import "@frontend/ui-kit/src/runtime/styles/borderRadius";

.notifications-container {
  position: absolute;
  z-index: 9999;
  top: 56px;
  right: 12px;
  width: 350px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .notification-item {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    min-height: 40px;
    padding: 10px 12px;
    border-radius: $ui-kit-border-radius-4x;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #fff;
    filter: drop-shadow(0px 7px 18px rgba(15, 0, 30, 0.15)) drop-shadow(0px 0px 1px rgba(15, 0, 30, 0.15));

    &._success {
      background-color: #0DAF7A;
    }

    &._error {
      background-color: #D60745;
    }

    .close-btn {
      margin-left: 8px;

      &:hover {
        color: #32284B;
      }
    }
  }

  .notification-move,
  .notification-enter-active,
  .notification-leave-active {
    transition: all 0.3s ease;
  }

  .notification-enter,
  .notification-leave-to {
    opacity: 0;
    transform: translateX(30px);
  }
}

.notification-message {
  white-space: pre-wrap;
}
</style>
