<script setup lang="ts">
import { storeToRefs } from 'pinia';

defineOptions({
  name: 'PageLoading',
});

const appStore = useAppStore();
const { isLoading, loadingMessage } = storeToRefs(appStore);
</script>

<template lang="pug">
.loading-container.flex.col-24(v-show="isLoading")
  UiLoading(:isShow="true")
  span.text.typ-20-28-500.flex-center-center(v-if="loadingMessage") {{ typeof loadingMessage === 'object' ? $t(...loadingMessage) : $t(loadingMessage) }}
</template>

<style lang="scss" scoped>
@import "@frontend/ui-kit/src/runtime/styles/colors";

.loading-container {
  z-index: 100000;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: center;
  align-items: center;
}

.text {
  letter-spacing: 0.1px;
  color: $ui-color-text-default;
}
</style>
